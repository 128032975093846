import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'animate.css'
import './assets/css/style.css'

Vue.config.productionTip = false

/** Create Bus to communicate outside Vue instance */
window.event = new Vue()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
