<template>
    <main>
        <!-- Header -->
        <header class="w-full relative" :style="bgHeader">
            <!-- Side band -->
            <div class="band w-2 h-full absolute bg-yellow-3"></div>
            <!-- Logo -->
            <div class="logo flex justify-end container mx-auto px-6 max-w-4xl lg:max-w-5xl xl:max-w-7xl">
                <div class="z-50 bg-white rounded-b-2xl py-1 px-4 md:px-4 lg:-mr-6 2xl:-mr-24 shadow-sm">
                    <img class="w-20 inline-block sm:hidden" src="@/assets/images/logo.svg" alt="Cofidis Logo" title="Cofidis Logo" />
                    <img class="w-40 hidden sm:inline-block" src="@/assets/images/logo-claim.svg" alt="Cofidis Logo" title="Cofidis Logo" />
                    <img class="w-20 sm:w-40 inline-block border-gray-700 border-opacity-30 border-l ml-4 pl-4" src="@/assets/images/logo-power-credit.svg" alt="Cofidis Logo" title="PowerCredit Logo" />
                </div>
            </div>
            <div class="container mx-auto max-w-4xl lg:max-w-5xl xl:max-w-7xl px-6 2xl:px-0 mt-28 sm:mt-4">
                <div class="px-4 md:px-14 lg:px-2">                       
                    <h1 class="slanted sm:ml-4 px-4 uppercase">power</h1>
                    <br class="block sm:hidden">
                    <h1 class="slanted px-4 sm:-ml-1 uppercase">credit</h1>                
                </div>
                <div class="mb-2 md:px-14 px-4 lg:px-2">
                    <p class="text-xl font-light text-white md:text-3xl md:mb-8 inline-block py-1 pr-4">
                        Tra il problema e<br class="block sm:hidden"> la soluzione<br> c'è Power Credit
                    </p>
                </div>
            </div>            
        </header>

        <!-- Section claim -->
        <section class="section-icons mx-auto">
            <div class="bg-white mx-auto text-center px-6 sm:px-10 md:px-6">
                <div class="mx-auto max-w-4xl lg:max-w-5xl xl:max-w-7xl py-6 sm:pt-10 lg:pt-14 sm:pb-6">
                    <p class="text-xl text-gray-5 font-semibold px-4 sm:px-8 text-3xl md:text-4xl lg:text-5xl">
                        Ti bastano tre minuti,<br class="sm:hidden"> ovunque tu sia
                    </p>
                    <h2 class="mt-2 sm:mt-2 text-xl sm:text-2xl text-gray-5 font-light px-0 sm:px-16">
                        Attiva gratuitamente Power Credit e avrai una linea di credito di 2.000€ sempre a tua disposizione, da utilizzare quando vuoi e per ogni acquisto o imprevisto come:
                    </h2>
                    <div class="flex flex-wrap items-start py-4 sm:py-4 lg:px-32">                        
                        <!-- Icons -->
                        <div class="w-1/2 sm:w-1/4 text-center my-4 animate__animated animateFadeInUp animate__1">
                            <i class="icon car align-middle w-20 h-20 block mx-auto"></i>
                            <h4 class="block text-sm xl:inline-block lg:px-2 font-semibold">
                                Riparare <br class="sm:hidden" />
                                l'auto
                            </h4>
                        </div>
                        <div class="w-1/2 sm:w-1/4 text-center my-4 animate__animated animateFadeInUp animate__2">
                            <i class="icon tooth align-middle w-20 h-20 block mx-auto"></i>
                            <h4 class="block text-sm xl:inline-block lg:px-2 font-semibold">
                                Prenderti <br class="sm:hidden" />
                                cura di te
                            </h4>
                        </div>
                        <div class="w-1/2 sm:w-1/4 text-center my-4 animate__animated animateFadeInUp animate__3">
                            <i class="icon palm align-middle w-20 h-20 block mx-auto"></i>
                            <h4 class="block text-sm xl:inline-block lg:px-2 font-semibold">
                                Occasioni <br class="sm:hidden" />
                                irripetibili
                            </h4>
                        </div>
                        <div class="w-1/2 sm:w-1/4 text-center my-4 animate__animated animateFadeInUp animate__4">
                            <i class="icon money align-middle w-20 h-20 block mx-auto"></i>
                            <h4 class="block text-sm xl:inline-block lg:px-2 font-semibold">
                                Spese <br class="sm:hidden" />
                                inaspettate
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <a id="ctaFloat" :href="formsUrl" class="w-4/5 sm:w-auto font-semibold text-lg text-gray-4 bg-orange-1 uppercase max-w-xs rounded-full shadow-2xl px-16 py-3 sm:max-w-none fixed bottom-10 left-1/2 transform -translate-x-1/2 z-50"><span class="block text-center">ATTIVALO GRATIS</span></a>
        
        <!-- Section claim -->
        <section class="section-claim mx-auto text-center px-4 sm:px-10">
            <div class="mx-auto max-w-4xl lg:max-w-5xl xl:max-w-5xl py-10 sm:py-10">
                <p class="text-xl text-gray-5 font-semibold text-3xl sm:px-8 lg:text-5xl">
                    Non lo usi?  Non lo paghi!
                </p>
                <h2 class="mt-2 sm:mt-2 text-xl sm:text-2xl text-gray-5 font-light px-4 sm:px-16">
                    Attiva Power Credit gratis per avere 2.000€* di credito da tenere lì, per ogni evenienza.  Paghi solo quando inizi a usarli.
                </h2>
            
                <div class="bg-orange-1 rounded-3xl shadow-2xl px-10 sm:px-4 py-10 mx-auto mt-10 flex flex-wrap items-start">
                    <div class="w-full pb-5 mb-5 border-b-2 border-gray-700 border-opacity-30 sm:p-0 sm:w-1/3 sm:border-b-0 sm:border-r-2 sm:inline-block  animate__animated animateFadeInUp animate__1">
                        <img class="mx-auto h-18" alt="Group" title="Group" src="@/assets/images/icon_veloce.svg" />
                        <h3 class="lg:mt-2">
                            <strong class="font-semibold text-2xl text-gray-4 sm:text-xl block">Veloce da richiedere</strong>
                            <span class="block sm:mt-9 lg:mt-2 text-base sm:px-10">Bastano tre minuti e pochi click per attivarlo, con primo esito in tempo reale.</span>
                        </h3>
                    </div>
                    <div class="w-full pb-5 mb-5 border-b-2 border-gray-700 border-opacity-30 sm:p-0 sm:w-1/3 sm:border-b-0 sm:border-r-2 sm:inline-block  animate__animated animateFadeInUp animate__2">
                        <img class="mx-auto h-18" alt="Group" title="Group" src="@/assets/images/icon_ricaricabile.svg" />
                        <h3 class="lg:mt-2">
                            <strong class="font-semibold text-2xl text-gray-4 sm:text-xl block px-2">2.000€ per te, semplici e ricaricabili</strong>
                            <span class="block sm:mt-2 text-base sm:px-10">Come funziona? Ricarichi il credito con una rata fissa mensile di soli 60€. Facile, no?</span>
                        </h3>
                    </div>
                    <div class="w-full sm:w-1/3 sm:inline-block  animate__animated animateFadeInUp animate__3">
                        <img class="mx-auto h-18" alt="Group" title="Group" src="@/assets/images/icon_gratuito.svg" />
                        <h3 class="lg:mt-2">
                            <strong class="font-semibold text-2xl text-gray-4 sm:text-xl block">Attivazione gratuita</strong>
                            <span class="block sm:mt-9 lg:mt-2 text-base sm:px-10">Ricevi subito 2.000€ sul conto corrente* ma se non li usi non pagherai mai nulla.</span>
                        </h3>
                    </div>
                </div>
                <div class="text-center mt-6 sm:mt-14">
                    <span class="font-light text-sm sm:text-base">*Salvo approvazione di Cofidis S.A.</span>
                </div>
            </div>
        </section>

        <!-- Section steps -->
        <section class="section-steps bg-gray-5 pt-10 pb-10">
            <div class="container mx-auto max-w-7xl">
                <!-- Title -->
                <div class="text-center mb-8 sm:mb-14">
                    <h3 class="font-semibold text-3xl text-white md:text-5xl">
                        Come funziona<br class="sm:hidden" />
                        Power Credit
                    </h3>
                </div>
                <!-- Steps -->
                <div class="sm:mb-6 flex flex-wrap">
                    <!-- Step 1 -->
                    <div class="text-center px-6 mb-6 w-full sm:w-1/2 sm:px-4 lg:w-1/3 lg:flex lg:flex-1">
                        <div class="w-full text-left bg-white rounded-3xl shadow-2xl pt-4 pb-6 px-6 sm:h-40 lg:h-auto animate__animated animateFadeInUp animate__1">
                            <span class="block font-bold text-sm text-gray-4 mb-2">Step 1</span>
                            <p class="font-semibold text-3xl text-red-1">Invia la tua richiesta</p>
                            <p class="font-light text-gray-4 mt-2">Richiedi Power Credit gratis in pochi click.</p>
                        </div>
                    </div>
                    <!-- Step 2 -->
                    <div class="text-center px-6 mb-6 w-full sm:w-1/2 sm:px-4 lg:w-1/3 lg:flex lg:flex-1">
                        <div class="w-full text-left bg-white rounded-3xl shadow-2xl pt-4 pb-6 px-6 sm:h-40 lg:h-auto
                            animate__animated animateFadeInUp animate__2">
                            <span class="block font-bold text-sm text-gray-4 mb-2">Step 2</span>
                            <p class="font-semibold text-3xl text-red-1">Ricevi 2.000€ sul conto corrente*</p>
                            <p class="font-light text-gray-4 mt-2">E usali quando e come vuoi: per una spesa, uno sfizio, un regalo...</p>
                        </div>
                    </div>
                    <!-- Step 3 -->
                    <div class="text-center px-6 mb-6 w-full sm:w-1/2 sm:px-4 lg:w-1/3 lg:flex lg:flex-1">
                        <div class="w-full text-left bg-white rounded-3xl shadow-2xl pt-4 pb-6 px-6 sm:h-40 lg:h-auto animate__animated animateFadeInUp animate__4">
                            <span class="block font-bold text-sm text-gray-4 mb-2">Step 3</span>
                            <p class="font-semibold text-3xl text-red-1">Lo rimborsi con 60€ al mese</p>
                            <p class="font-light text-gray-4 mt-2">Ricorda: solo se usi il credito, altrimenti non paghi nulla!</p>
                        </div>
                    </div>
                    <!-- Step 4 -->
                    <div class="text-center px-6 mb-6 w-full sm:w-1/2 sm:px-4 lg:w-1/3 lg:flex lg:flex-1">
                        <div class="w-full text-left bg-white rounded-3xl shadow-2xl pt-4 pb-6 px-6 sm:h-40 lg:h-auto animate__animated animateFadeInUp animate__4">
                            <span class="block font-bold text-sm text-gray-4 mb-2">Step 4</span>
                            <p class="font-semibold text-3xl text-red-1">Fiducia continua ai tuoi acquisti</p>
                            <p class="font-light text-gray-4 mt-2">Pagando la rata mensile il tuo credito si ricarica.</p>
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <span class="font-light text-white text-sm sm:text-base">*Salvo approvazione di Cofidis S.A.</span>
                </div>
            </div>
        </section>
     
        <!-- Section banner -->
        <section class="section-banner py-10 md:py-16 lg:py-20 sm:px-4" :style="bgBanner">
            <div class="container mx-auto flex flex-wrap">
                <div class="w-full lg:w-1/2 text-center px-6 mb-8 md:inline-block md:px-2 lg:mb-0">
                    <div class="bg-orange-1 rounded-3xl shadow-2xl px-10 sm:px-4 py-4 mx-auto xl:w-3/4">
                        <div class="">
                            <p class="text-xl text-gray-5 font-semibold mb-6 sm:mb-10 px-4 lg:text-3xl sm:px-8">
                                Abbiamo dato fiducia a
                            </p>
                        </div>
                        <div class="w-full pb-5 mb-5 border-b-2 border-gray-700 border-opacity-30 sm:p-0 sm:w-1/2 sm:border-b-0 sm:border-r-2 sm:inline-block">
                            <img class="mx-auto h-14" alt="Group" title="Group" src="@/assets/images/cofidis-milioni-clienti.svg" />
                            <h3 class="lg:mt-2">
                                <strong class="font-semibold text-2xl text-gray-4 sm:text-3xl lg:text-4xl block">30 milioni</strong>
                                <span class="text-lg uppercase"> di clienti </span>
                            </h3>
                        </div>
                        <div class="w-full sm:w-1/2 sm:inline-block">
                            <img class="mx-auto h-14" alt="Pin" title="Pin" src="@/assets/images/cofidis-nove-paesi-europa.svg" />
                            <h3 class="lg:mt-2">
                                    <strong class="font-semibold text-2xl text-gray-4 sm:text-3xl lg:text-4xl block">in 9 paesi</strong>
                                <span class="text-lg uppercase"> in Europa </span>
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="w-full lg:w-1/2 text-center px-6 md:inline-block md:px-2">
                    <div class="bg-orange-1 rounded-3xl shadow-2xl px-10 sm:px-4 pt-4 pb-3 mx-auto xl:w-3/4">
                        <div class="">
                            <p class="text-xl text-gray-5 font-semibold mb-4 px-4 lg:text-3xl sm:px-8">
                                Con ottimi risultati
                            </p>
                        </div>
                        <div class="flex flex-wrap items-center sm:pb-4">
                            <div class="google-rating-wrapper">
                                <img class="mx-auto w-36" alt="Prestito Personale – Google Rating" title="Google Rating" :src="`${commonDistributionUrl}/Google_rating_stelle_bianche.png`" />
                            </div>
                            <div class="w-full sm:w-1/2 sm:inline-block sm:px-4">
                                <img class="mx-auto" alt="Prestito Personale – TrustPilot 2022" title="TrustPilot 2022" :src="`${commonDistributionUrl}/TrustPilot_Cofidis_2022.png`" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Section cofidis leader -->
        <section class="section-leader bg-gray-5 py-8">
            <div class="container mx-auto">
                <div class="w-full relative flex flex-wrap items-center justify-center lg:h-48">
                    <img class="sm:mr-10 h-40" alt="Prestito Personale – Cofidis Certificazione Ottima Finanziaria" title="Logo Certificazione Ottima Finanziaria" :src="`${commonDistributionUrl}/ottima_finanziaria_2022.png`" />
                    <h3 class="w-full sm:w-auto font-semibold text-2xl text-white leading-none md:text-3xl lg:text-4xl mt-6 sm:mt-0 text-center">Cofidis è il leader del credito a distanza</h3>
                </div>
            </div>
        </section>
       
        <!-- Section widget -->
        <section class="section-widget md:mt-0 p-6 sm:py-10 lg:py-16 bg-white">
            <div class="container mx-auto">
                <!-- Title -->
                <div class="text-center mb-8 sm:mb-16">
                    <h3 class="font-semibold text-3xl text-gray-5 leading-none md:text-5xl">
                        Cosa dicono i <br class="sm:hidden" />
                        nostri clienti
                    </h3>
                </div>
                <!-- TrustBox widget - Carousel -->
                <div class="trustpilot-widget" data-locale="it-IT" data-template-id="54ad5defc6454f065c28af8b" data-businessunit-id="5d0a61ad3bcbc70001701a39" data-style-height="240px" data-style-width="100%" data-theme="Organization" data-stars="4,5" data-review-languages="it">
                  <a href="https://it.trustpilot.com/review/www.prestitocofidis.com" target="_blank" rel="noopener">Trustpilot</a>
                </div>
                <!-- End TrustBox widget -->                
            </div>
        </section>

       <!-- Section faq -->
        <section class="section-faq pt-10 pb-4 bg-gray-1 lg:pt-16">
            <div class="sm:container px-6 mx-auto">
                <h3 class="font-semibold text-3xl text-gray-5 leading-none px-6 mb-14 text-center lg:text-4xl">
                    Trova la risposta <br class="sm:hidden" />
                    alle tue domande
                </h3>
                <div class="faq-container w-full flex flex-wrap hover:text-orange cursor-pointer mb-10" :class="{ hidden: i > 3 && !allFaqs }" v-for="(faq, i) in faqs" :key="i">
                    <div class="h-10 w-10 text-center rounded-full leading-10 mr-5 -mt-2 outline-none flex items-center justify-center" 
                    :class="{
                        'bg-gray-2': activeFaq != i,
                        'bg-orange-1 shadow-xl': activeFaq == i
                    }"
                    @click="toggleFaq(i)">
                        <svg xmlns="http://www.w3.org/2000/svg" class="text-white h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor" :class="{'transform rotate-180': activeFaq != i}">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                        </svg>
                    </div>
                    <div class="w-4/5">
                        <div class="faq-question mb-4 outline-none" :class="{ active: activeFaq == 1 }" @click="toggleFaq(i)">
                            <span class="font-semibold text-2xl text-gray-5 leading-none">{{
                                faq.q
                                }}</span>
                        </div>
                        <SlideUpDown class="faq-content mb-4 font-medium text-gray-5 cursor-default outline-none" :active="activeFaq == i"><span class="font-light text-lg text-gray-5" v-html="faq.a"></span></SlideUpDown>
                    </div>
                </div>
                <!-- <div class="text-center" v-if="allFaqs" @click="allFaqs = false">
                    <p class="inline font-semibold text-lg text-red-1 text-center cursor-pointer mt-6 uppercase border-b-4 border-red-1">
                        Mostra meno
                    </p>
                </div>
                <div v-else class="text-center" @click="allFaqs = true">
                    <p class="inline font-semibold text-lg text-red-1 text-center cursor-pointer mt-6 uppercase border-b-4 border-red-1">
                        Carica altre
                    </p>
                </div> -->
            </div>
        </section>
        <!-- Section amount -->
        <section class="section-amount bg-gray-5 py-10">
            <div class="container mx-auto px-2 sm:px-10 text-center">
                <h3 class="font-semibold text-3xl text-white leading-tight px-8 mb-4 sm:px-0 md:text-4xl">
                    Attiva gratis<br class="sm:hidden" />
                    Power Credit
                </h3>
                <p class="font-light text-lg text-white mb-5 lg:text-xl">
                    E se poi non lo usi? Non paghi nulla!
                </p>

                <a id="ctaFixed" :href="formsUrl" class="invisible inline-block w-4/5 sm:w-auto font-semibold text-lg text-gray-4 bg-orange-1 uppercase max-w-xs rounded-full shadow-2xl px-16 py-3 sm:max-w-none"><span class="block">ATTIVALO GRATIS</span></a>
            </div>
        </section>
        <!-- Section logos -->
        <section class="section-logos">
            <div class="container flex flex-wrap items-center mx-auto px-8 py-10 md:py-16 lg:px-32">
                <div class="w-1/2 text-center mb-6 sm:w-1/4 sm:mb-0">
                    <img class="mx-auto w-40" alt="Prestito Personale – Google Rating" title="Google Rating" :src="`${commonDistributionUrl}/Google_rating_stelle_gialle.png`" />
                </div>
                <div class="w-1/2 text-center mb-6 sm:w-1/4 sm:mb-0">
                    <img class="mx-auto w-52" alt="Prestito Personale – Best Workplaces" title="Logo Best Workplaces" :src="`${commonDistributionUrl}/logo_best_workplaces.png`" />
                </div>
                <div class="w-1/2 text-center mb-6 sm:w-1/4 sm:mb-0">
                    <img class="mx-auto w-24 lg:w-32" alt="Prestito Personale – Cofidis Certificazione Ottima Finanziaria" title="Logo Certificazione Ottima Finanziaria" :src="`${commonDistributionUrl}/ottima_finanziaria_2022.png`" />
                </div>
                <div class="w-1/2 text-center mb-6 sm:w-1/4 sm:mb-0">
                    <img class="mx-auto" alt="Prestito Personale – TrustPilot 2022" title="TrustPilot 2022" :src="`${commonDistributionUrl}/TrustPilot_Cofidis_2022.png`" />
                </div>
            </div>
        </section>
        <YouTubeModal :videoId="`7xj8ZX57zYo`" ref="videoFirmaDigitale" />

        <footer class="bg-gray-4 text-white">
            <div class="w-11/12 py-6 mx-auto sm:max-w-7xl relative">
                <a href="#" @click.prevent="scrollToTop()" class="absolute shadow-arrow rounded-full bg-white bg-center bg-no-repeat w-12 h-12 right-0 top-0 -mt-6 mr-5">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="inline-block text-gray-500 h-8 mt-2 ml-2">
                        <path fill-rule="evenodd" d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                    </svg>
                </a>
                <div class="footer-logo">
                    <img class="w-36" src="@/assets/images/logo-claim-white.svg" />
                </div>
                <div class="footer-content mt-4">
                    <h4 class="text-base font-semibold mb-5 sm:text-2xl">
                        Informazioni legali
                    </h4>
                    <p class="mb-5">
                        Per tutte le condizioni economiche e contrattuali, fare riferimento alle Informazioni Europee di Base sul Credito ai Consumatori (cd. <a href="https://cofidis-secci.s3.eu-central-1.amazonaws.com/revolving/prod/SECCI_REV_1120_compilato_no_promo.pdf" class="underline" target="_blank">SECCI</a>) sul sito <a href="https://www.cofidis.it/trasparenza/" class="underline" target="_blank">www.cofidis.it/trasparenza</a>.<br> 
                        Esempio rappresentativo con TAEG calcolato sulla base delle ipotesi che l’Importo totale del credito, fornito per un anno, sia utilizzato da subito
                        e per intero al primo utilizzo; che sia applicato unicamente il Tasso Standard e non anche quello promozionale; che il Contratto resti valido e
                        che il consumatore adempia ai propri obblighi nei termini ed entro le date convenute nel Contratto; che siano pagate rate mensili conteggiate
                        suddividendo l’importo totale del credito in 12 quote uguali (quota capitale costante); ad ogni quota è aggiunta una componente di interessi variabile
                        maturata mensilmente e calcolata sul capitale residuo riferito al periodo precedente, sulla base del TAN FISSO - Importo totale del credito €2.000 -
                        rate minime mensili €60 da rimborsare mediante addebito in conto corrente - TAN fisso standard 19,80% - TAEG 21,70%. Spese di istruttoria €0;
                        spese di incasso rata €0; imposta di bollo sul contratto/imposta sostitutiva €0; spese di invio Rendiconto mensile €0; imposta di bollo su
                        Rendiconto mensile €0. Importo totale dovuto €2.214,50. La richiesta di finanziamento e di ogni utilizzo successivo sono soggette alla valutazione
                        da parte di Cofidis S.A.
                    </p>
                    <p class="my-3">
                        <b>Cofidis Italia</b><br />&copy; Copyright {{ year }}
                    </p>
                    <p class="mb-3">
                        Capitale Sociale Euro 67.500.000,00 i.v. - Società soggetta a direzione e coordinamento di Banque Fédérative du Crédit Mutuel S.A – soggetta alla vigilanza della Banca di Francia (Autorité de Contrôle Prudentiel et de Résolution) e, in qualità di succursale italiana di banca comunitaria, alla vigilanza della Banca d’Italia, per le materie a questa demandate dalle vigenti disposizioni normative.
                    </p>
                    <a href="https://www.cofidis.it/it/privacy-policy.html" class="underline" target="_blank">Privacy Policy</a>  |
                    <a href="https://cofidis-agid.s3.eu-central-1.amazonaws.com/Dichiarazione+di+non+accessibilita%CC%80+Powercredit.pdf" class="underline" target="_blank">Accessibilità: non conforme</a>
                </div>
            </div>
        </footer>
    </main>
</template>
<script>
// @ is an alias to /src
import Swiper from "swiper";
import SlideUpDown from 'vue-slide-up-down'
import YouTubeModal from "@/components/YouTubeModal";

export default {
    name: "Home",
    components: {
        YouTubeModal,
        SlideUpDown
    },
    methods: {
        scrollToTop() {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        },
        toggleFaq(i) {
            if (this.activeFaq == i) this.activeFaq = null;
            else this.activeFaq = i;
        },
        redirect() {
            this.$store.dispatch("redirect", { step: "home", route: "ContattiPowerCredit" });
        }
    },
    computed: {
        bgHeader() {
            const env = process.env.VUE_APP_ENV == "production" ? 'prod' : 'stage'            
            return {
                '--bg-header': `#fff url("${this.distributionUrl}/${env}/header.jpg") 55% 0% no-repeat`,
                '--bg-header-sm': `#fff url("${this.distributionUrl}/${env}/header-sm.jpg") center top no-repeat`,
            }
        },
        bgBanner() {
            return {
                '--bg-banner': `#fff url("${this.distributionUrl}/credito-cofidis-banner.png") 60% top no-repeat`,
                '--bg-banner-sm': `#fff url("${this.distributionUrl}/credito-cofidis-banner-sm.png") 60% top no-repeat`,
            }
        },
        formsUrl() {
            const baseUrl = 'https://prestitocofidis.it/powercredit/?allowed_referral'            
            const urlParts = window.location.href.split('?')
            if(urlParts[1])
                return `${baseUrl}&${urlParts[1]}`
            else
                return baseUrl
        },
        year() {
            return (new Date()).getFullYear()
        }
    },
    data() {
        return {
            rating: 0,
            ratingsTotal: 0,
            activeFaq: null,
            allFaqs: false,
            distributionUrl: "https://d4ybvu6hnmspp.cloudfront.net/assets_front/powercredit",
            commonDistributionUrl: "https://d4ybvu6hnmspp.cloudfront.net/assets_front/common",
            faqs: [{
                    q: "Cosa mi serve per richiedere Power Credit?",
                    a: `Per inoltrare la richiesta on line ti bastano solo i tuoi dati personali, il codice fiscale e l’Iban del tuo conto corrente. È semplice fai tutto in meno di 3 minuti.`
                },
                {
                    q: "In quanto tempo riceverò la somma richiesta?",
                    a: `Una volta firmato il contratto ed inviato tutti i documenti, riceverai i soldi sul tuo conto corrente in meno di 48 ore.`,
                },
                {
                    q: "Quanto costa attivare Power Credit?",
                    a: `È gratis, attivare Power Credit non ha nessun costo. Se non lo utilizzi non pagherai mai nulla.`,
                },
                {
                    q: "Cos’è un contratto con firma digitale?",
                    a: `<span class="sm:mr-2">È un contratto che ricevi via e-mail e puoi firmare con una OTP (One Time Password) cioè un codice numerico che ricevi via SMS sul tuo smartphone.</span>
                        <button id="playVideo" class="inline-block font-medium text-lg text-red-1 text-center cursor-pointer  border-b-2 border-red-1 cursor-pointer">Guarda il video</button>`,
                }
            ],
        };
    },
    created() {
        const vm = this;
        
        window.event.$on('cookiePreferencesUpdated', () => {
            console.log('cookiePreferencesUpdated')
            if (!window.dataLayer) return;

            for (var i in window.dataLayer) {                
                if (window.dataLayer[i].event && window.dataLayer[i].event == "OneTrustLoaded") {
                    const consentGroups = window.dataLayer[i].OnetrustActiveGroups.split(',')
                    if (consentGroups && consentGroups.includes('C0004')) {                        
                        let script = document.createElement("script");
                        script.src = "//www.youtube.com/iframe_api";
                        window.onYouTubeIframeAPIReady = function() {                            
                            vm.$refs.videoFirmaDigitale.init()                         
                        };
                        document.head.appendChild(script);
                        
                        return true;
                    }                 
                }
            }            
        })
    },
    mounted() {
        const vm = this;
               
        const onPlayVideoBtnClick = function() {            
            vm.$refs.videoFirmaDigitale.play()           
        } 
        document.getElementById('playVideo').addEventListener("click", onPlayVideoBtnClick);

        // load trustpilot script
        let script = document.createElement("script");
        script.src = "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
        document.head.appendChild(script);
        //reload widgets
        document.querySelectorAll(".trustpilot-widget").forEach(function(widget) {
            if (window.Trustpilot) window.Trustpilot.loadFromElement(widget);
        });

        new Swiper("#trustpilot-sw.swiper-container", {
            slidesPerView: 1,
            spaceBetween: 30,
            pagination: {
                el: "#trustpilot-sw .swiper-pagination",
                clickable: true,
            },
            navigation: {
                nextEl: "#trustpilot-sw .swiper-button-next",
                prevEl: "#trustpilot-sw .swiper-button-prev",
            },
        });

        // animation interesection
        window.onscroll = function() {
            const animateFadeInUp = document.getElementsByClassName("animateFadeInUp");
            //const animateFadeIn = document.getElementsByClassName("animateFadeIn");
            const windowOffsetTop = window.innerHeight + window.scrollY;

            Array.prototype.forEach.call(animateFadeInUp, (animatedBox) => {
                const animatedBoxOffsetTop = animatedBox.offsetTop;
                if (windowOffsetTop >= animatedBoxOffsetTop) {
                    animatedBox.classList.add('animate__fadeInUp')                     
                }
            });

            // float cta
            const ctaFloat = document.getElementById('ctaFloat')
            const ctaFixed = document.getElementById('ctaFixed')            
            
            if ((ctaFloat.offsetTop + window.scrollY) >= ctaFixed.offsetTop) {
                ctaFloat.classList.add('hidden')                     
                ctaFixed.classList.remove('invisible')  
            }
            if (ctaFixed.offsetTop > windowOffsetTop) {
                ctaFixed.classList.add('invisible')    
                ctaFloat.classList.remove('hidden')  
            }
        }
    }
};
</script>